<template>
  <div>
    <MainCard class="pa-4">
      <template v-slot:body>
        <v-card-text>
          <v-row class="align-center">
            <v-col
              xl="5"
              lg="5"
              md="5"
              sm="12"
              cols="12"
            >
              <h3 class="d-block">
                Hasil {{ cbt.name }}
              </h3>
            </v-col>
            <v-col
              xl="4"
              lg="4"
              md="4"
              sm="12"
              cols="12"
            >
              <v-text-field
                v-model="search"
                label="Cari"
                :append-icon="icons.mdiMagnify"
                outlined
                dense
                hide-details="none"
              ></v-text-field>
            </v-col>
            <v-col
              xl="3"
              lg="3"
              md="3"
              sm="12"
              cols="12"
              class="py-2"
            >
              <v-btn
                color="primary"
                large
                width="100%"
                :loading="isLoadingButton"
                @click="exportHandler"
              >
                Export Hasil Cbt
              </v-btn>
            </v-col>
          </v-row>
          <div>
            <div
              class="d-md-flex justify-space-between align-center my-8"
            >
              <div class="d-flex align-center mb-lg-0 mb-4">
                <div class="me-2">
                  <v-img src="@/assets/icons/user.svg"></v-img>
                </div>
                <div>
                  Pembuat CBT
                </div>
              </div>
              <div class="d-flex align-center mb-lg-0 mb-4">
                <div class="me-2">
                  <v-img src="@/assets/icons/users.svg"></v-img>
                </div>
                <div>
                  {{ results.length }} Peserta
                </div>
              </div>
              <div class="d-flex align-center mb-lg-0 mb-4">
                <div class="me-2">
                  <v-img src="@/assets/icons/calendar.svg"></v-img>
                </div>
                <div>
                  {{ cbt.start_date }}
                </div>
              </div>
              <div class="d-flex align-center mb-lg-0 mb-4">
                <div class="me-2">
                  <v-img src="@/assets/icons/clock.svg"></v-img>
                </div>
                <div>
                  {{ cbt.duration }} Menit
                </div>
              </div>
              <div class="d-flex align-center mb-lg-0 mb-4">
                <div class="me-2">
                  <v-img src="@/assets/icons/document-text.svg"></v-img>
                </div>
                <div>
                  {{ cbt.question.length }} Soal
                </div>
              </div>
              <div class="d-flex align-center">
                Token: <h3 class="ms-2">
                  {{ cbt.token }}
                </h3>
              </div>
            </div>
            <v-card
              v-for="result,index in results"
              :key="index"
              class="overflow-auto mb-2"
              outlined
            >
              <v-card-text>
                <v-row class="align-center flex-nowrap">
                  <v-col
                    md="1"
                    cols="3"
                  >
                    <v-avatar
                      v-show="false"
                      class="avatar-no-img"
                    >
                      <span>10</span>
                    </v-avatar>
                    <v-avatar
                      size="40"
                    >
                      <v-img
                        v-if="index === 0"
                        src="@/assets/icons/rank-1.svg"
                      ></v-img>

                      <v-img
                        v-else-if="index === 1"
                        src="@/assets/icons/rank-2.svg"
                      ></v-img>

                      <v-img
                        v-else-if="index === 2"
                        src="@/assets/icons/rank-3.svg"
                      ></v-img>

                      <h3 v-else>
                        {{ index+1 }}
                      </h3>
                    </v-avatar>
                  </v-col>
                  <v-col
                    md="4"
                    cols="6"
                  >
                    <v-avatar size="40">
                      <v-img
                        v-if="result.details[0].image"
                        :src="result.details[0].image"
                      ></v-img>
                      <v-img
                        v-else
                        src="@/assets/images/avatars/ari.png"
                      ></v-img>
                    </v-avatar>

                    <span class="d-inline-block pl-4">{{ result.name }}</span>
                  </v-col>
                  <v-col
                    md="2"
                    cols="6"
                  >
                    <span>Total Nilai: <span class="font-weight-bold">{{ result.total_point }}</span></span>
                  </v-col>
                  <v-col
                    md="2"
                    cols="6"
                  >
                    <span>Jawaban Bener: <span class="font-weight-bold">{{ result.true }}</span></span>
                  </v-col>
                  <v-col
                    md="2"
                    cols="6"
                  >
                    <span>Jawaban Salah: <span class="font-weight-bold">{{ result.false }}</span></span>
                  </v-col>
                  <v-col
                    md="1"
                    cols="3"
                    class="d-flex justify-md-end"
                  >
                    <v-btn
                      icon
                      :to="{ name: url, params: { cbtUuid: cbt_uuid, userUuid: result.uuid }}"
                    >
                      <v-icon>{{ icons.mdiChevronRight }}</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-skeleton-loader
              v-for="i,index in 6"
              v-show="results.length ? false : true"
              :key="index"
              type="image"
              height="80"
              class="mb-2"
            >
            </v-skeleton-loader>
          </div>
          <div class="d-flex justify-center">
            <div
              v-if="isEmpty"
              class="pa-4 text-center"
            >
              <v-img
                src="@/assets/images/vector/cbt-empty.svg"
                class="mx-8 my-7"
              ></v-img>
              <p class="my-7">
                Belum ada yang mengikuti CBT
              </p>
            </div>
          </div>
        </v-card-text>
      </template>
    </MainCard>
  </div>
</template>

<script>
import MainCard from '@/views/components/MainCard.vue'
import { mdiChevronRight, mdiMagnify } from '@mdi/js'

export default {
  name: 'CbtResult',
  components: {
    MainCard,
  },
  data() {
    return {
      icons: {
        mdiChevronRight,
        mdiMagnify,
      },
      cbtName: '',
      search: '',
      results: [],
      service: 'ranking',
      cbt: {},
      cbt_uuid: '',
      role: '',
      url: '',
      isLoadingButton: false,
      isEmpty: false,
      isLoadingCard: false,
    }
  },
  watch: {
    search: {
      handler() {
        if (this.search.length > 3 || this.search.length === 0) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.getRangking()
          }, 500)
        }
      },
    },
  },
  mounted() {
    this.getRole()
    this.getRangking()
  },
  methods: {
    getRole() {
      this.role = JSON.parse(localStorage.getItem('user')).user.role
      if (this.role === 'admin') {
        this.url = 'cbt-and-result-detail-admin'
      } else if (this.role === 'school') {
        this.url = 'cbt-and-result-detail-school'
      } else if (this.role === 'headmaster') {
        this.url = 'cbt-and-result-detail-headmaster'
      } else if (this.role === 'headdepartment') {
        this.url = 'cbt-and-result-detail-headdepartment'
      } else {
        this.url = ''
      }
    },
    async getRangking(params = {}) {
      this.cbt = JSON.parse(localStorage.getItem('cbt'))
      this.cbt_uuid = JSON.parse(localStorage.getItem('cbt')).uuid
      await this.$services.ApiServices.getParams('ranking', this.cbt_uuid, { ...params, search: this.search }).then(
        ({ data }) => {
          this.results = data.user
          this.isEmpty = false
          if (this.results.length < 1) {
            this.isEmpty = true
          }
        },
      )
    },
    async exportHandler() {
      this.isLoadingButton = true
      await this.$services.ExportParamsServices.export('exportcbtresult', this.cbt_uuid).then(
        data => {
          const url = window.URL.createObjectURL(new Blob([data.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'export-result-cbt.pdf')
          document.body.appendChild(link)
          link.click()
          this.isLoadingButton = false
        },
        err => {
          this.isLoadingButton = false
          console.error(err)
        },
      )
    },
  },
}
</script>

<style scoped>
.avatar-no-img {
  width: 55px !important;
  height: auto !important;
}

.v-input::v-deep .v-input__control > .v-input__slot {
  margin: 0 !important;
}

.v-input::v-deep .v-input__control > .v-text-field__details {
  display: none !important;
}
.v-application.theme--light .v-expansion-panel::before {
  box-shadow: none;
}
</style>
