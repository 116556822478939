<template>
  <div>
    <a
      class="text-decoration-none text-subtitle-2 font-weight-bold"
      @click="returnBack"
    >
      <v-icon
        color="primary"
        dense
        class="mr-2"
      >
        {{ icons.mdiArrowLeft }}
      </v-icon>
      Kembali
    </a>
    <v-tabs
      v-model="tab"
      show-arrows
      fixed-tabs
      class="mt-5"
    >
      <v-tab
        v-for="tabItem in tabs"
        :key="tabItem.title"
      >
        <v-icon
          class="me-2"
          size="20"
        >
          {{ tabItem.icon }}
        </v-icon>
        <span>{{ tabItem.title }}</span>
      </v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      elevation="0"
      class="mt-6"
    >
      <v-tab-item>
        <cbt-question></cbt-question>
      </v-tab-item>
      <v-tab-item>
        <cbt-result></cbt-result>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mdiArrowLeft, mdiDesktopClassic, mdiFileDocumentOutline } from '@mdi/js'
import CbtQuestion from './question/CbtQuestion.vue'
import CbtResult from './result/CbtResult.vue'

export default {
  name: 'CbtAndResult',
  components: {
    CbtQuestion,
    CbtResult,
  },
  data() {
    return {
      icons: {
        mdiDesktopClassic,
        mdiFileDocumentOutline,
        mdiArrowLeft,
      },
      tab: '',
      tabs: [
        { icon: mdiDesktopClassic, title: 'CBT' },
        { icon: mdiFileDocumentOutline, title: 'Hasil CBT' },
      ],
    }
  },
  methods: {
    returnBack() {
      this.$router.back()
    },
  },
  // setup() {
  //   const icons = ref({
  //     mdiDesktopClassic,
  //     mdiFileDocumentOutline,
  //     mdiArrowLeft,
  //   })
  //   const tab = ref('')
  //   const tabs = ref([
  //     { icon: mdiDesktopClassic, title: 'CBT' },
  //     { icon: mdiFileDocumentOutline, title: 'Hasil CBT' },
  //   ])

  //   return {
  //     icons,
  //     tab,
  //     tabs,
  //   }
  // },
}
</script>
<style scoped>
.v-application.theme--light .v-tabs:not(.v-tabs--vertical) {
  border-bottom: 1px solid rgba(94, 86, 105, 0.14) !important;
  box-shadow: none !important;
}

.v-tabs-items::v-deep,
.v-tabs::v-deep .v-tabs-bar {
  background: transparent !important;
}
</style>
